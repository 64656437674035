<template>
  <div v-if="workspaces" style="padding: 20px;">
    <v-row
      no-gutters
    >
      <v-col
      >
        <v-autocomplete 
          :disabled="loading"
          rounded
          solo-inverted
          item-text="Name"
          item-value="ID"
          :items="workspaces"
          v-model="selectedWorkspace"
          dense
        />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      v-if="selectedWorkspace"
    >
      <v-col
        style="padding: 0 40px;"
      >
        <v-text-field
          label="PrepareCronDays"
          outlined
          v-model="prepareCronDays"
          dense
          hideDetails
        />
      </v-col>
      <v-col
        style="padding: 0 40px;"
      >
        <v-btn
          @click="handleUpdateCronDays"
          :disabled="loading"
          block
        >
          actualizar prepareCronDays
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
    >
      <v-col
        style="padding: 20px 40px;"
      >
        <v-btn
          @click="handleGenerateEvents"
          :disabled="loading"
          block
        >
          generar eventos
        </v-btn>
      </v-col>
      <v-col
        style="padding: 20px 40px;"
      >
        {{info}}
      </v-col>
    </v-row>
  </div>
</template>
<script>
import api from '@/services/api'
export default {
  data: () => ({
    loading: false,
    workspaces: null,
    selectedWorkspace: null,
    info: null,
    prepareCronDays: null,
  }),
  computed: {
    environment () {
      return 'booking'
    },
  },
  watch: {
    selectedWorkspace (v) {
      const aux = this.workspaces.filter(x => x.ID === v).map(item => item.PrepareCronDays)
      this.prepareCronDays = aux.shift()
    },
  },
  mounted () {
    this.handleGetWorkspaces()
  },
  methods: {
    handleGetWorkspaces () {
      api.getAllWithoutLimit(this.environment, 'v1/system/workspaces/')
        .then(response => {
          response.data.unshift({ID: null, Name: 'Todos'})
          this.workspaces = response.data.map(item => {
            return {
              ID: item.ID,
              Name: item.Name.concat(item.PrepareCronDays ? ` (${item.PrepareCronDays})` : ''),
              PrepareCronDays: item.PrepareCronDays
            }
          })
        })
    },
    handleGenerateEvents () {
      this.loading = true
      this.info = 'INI...'
      api.put(this.environment, `v1/system/workspaces/${this.selectedWorkspace}/force-schedule`)
        .then(() => {
          this.info = 'COMPLETE'
          this.loading = false
        })
    },
    handleUpdateCronDays () {
      api.updateItem(this.environment, `v1/system/workspaces/`, `${this.selectedWorkspace}/prepare-cron-days`, { prepareCronDays: this.prepareCronDays && this.prepareCronDays.length > 0 ? this.prepareCronDays | 0 : null})
        .then(() => {
          alert('Complete')
          this.selectedWorkspace = null
          this.handleGetWorkspaces ()
        })
    },
  },
}
</script>

